<template>
  <Box class="fullscreen">
  
    <SpacerRow />
    <ErrorRow :error="error" /> 
    <SuccessRow :message="message" /> 
    
    <ProgressRow 
      :display="isSending" 
      :title="MC.Inspection.Email.Progress.Title.value()" 
      :message="MC.Inspection.Email.Progress.Message.value()" />
    
    <TitleRow :left="5" :right="5">
      {{ MC.Inspection.Email.PageTitle.value() }}
    </TitleRow>
    
    <ValueRow :left="5" :right="5">
      {{ MC.Inspection.Email.MtoMessageStart.value() }}
    </ValueRow>
    
    <KeyEmail
      :left="5" :right="5"
      :title="Fields.EMAIL" 
      :mandatory="true" 
      :required="isValueRequired(Fields.EMAIL)"
      :placeholder="MC.Inspection.Email.Email.value()" 
      v-on:value="setValue" />

    <ValueRow :left="5" :right="5">
      {{ MC.Inspection.Email.MtoMessageEnd.value() }}
    </ValueRow>

    <Row>
      <Column :width="5" />
      <Column :width="5">
        <Button :go="true" v-on:click="send">Send</Button>
      </Column>
      <Column :width="5" />
    </Row>
    <Row>
      <Column :width="5" />
        <Column :width="5">
          <Button v-on:click="back">Back</Button>
        </Column>
      <Column :width="5" />
    </Row>
    
    <LoadingRow 
      :left="5" :right="5" 
      :showBorder="true" 
      v-if="isLoading">{{ loadingMessage }}
    </LoadingRow>
  
  </Box>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import ProgressRow from '@/components/row/ProgressRow.vue'


import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import KeyEmail from '@/components/input/KeyEmail.vue';


export default {
  name: "inspection-email",
  components: {
    Box, Row, Column,
    SpacerRow, TitleRow, LoadingRow,
    Button, ValueRow,
    ErrorRow, SuccessRow,
    KeyEmail,
    ProgressRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      missing: null,
      
      isLoading: true,
      loadingMessage: null,
      MC: new MC(),
      Consts: ConstUtils,
      
      Strings: StringUtils,
      Contents: ContentUtils,
      
      Fields: {
        EMAIL: "email",
      },
      kvps: {},
      isSending: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    setValue: function (kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
      this.error = null;
      this.message = null;
      this.missing = null;
    },
    isValueRequired: function(field) {
      this.missing === field;
    },
    isRequirementsMet: function() {
      this.missing = null;
      var met = ContentUtils.hasValue(this.kvps, this.Fields.EMAIL);
      if (!met) {
        this.missing = this.Fields.EMAIL;
        return false;
      }
      return met;
    },
    back: function() {
      this.$router.back();
    },
    send: function() {
      if (!this.isRequirementsMet()) {
        return;
      }
      this.isSending = true;
      this.error = null;
      this.missing = null;
      
      var email = this.kvps[this.Fields.EMAIL];
      var inspectionId = this.itemId;
      var event = this.domain
          .events()
          .inspections().email(inspectionId, email);
      event.send(this.listener);
    },
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        this.$router.back();
      }
      this.isSending = false;
    },
   },
}
</script>